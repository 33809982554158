.companie_card {
    border: 5px solid transparent;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    background-color: white;
    transition: border 0.3s;
}

.companie_card.active {
    border: 5px inset var(--kss-gold-color);
    transition: border 0.3s;
}

.monitor_data_table {
    margin-top: 1rem;
    width: 100%;
}

.monitor_data_table thead,
.monitor_data_table tbody {
    text-align: center;
}

.lamp_row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.status_controll_lamp {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.status_controll_lamp.__green {
    background-color: green;
}

.status_controll_lamp.__red {
    background-color: red;
}

.status_controll_lamp.__yellow {
    background-color: yellow;
}

.companie_card_btn {
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
}

.companie_card_btn.__edit:hover {
    background-color: #52db12;
}

.companie_card_btn.__info:hover {
    background-color: var(--kss-gold-color);
}