.LoginPage{
    min-height: 100vh;
    display: grid;
    place-items: center;
}

.LoginScreenBox{
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
}

.LoginScreenBox form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.LoginScreenBox form input {
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
    width: 250px;
}

.LoginScreenBox form button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f1f1f1;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}