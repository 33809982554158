:root {
  --height_right_side_top: 400px;
  --height_right_side_bottom: 300px;
  --height_tracking_list: calc(var(--height_right_side_top) + var(--height_right_side_bottom));
  --kss-gold-color:rgb(202, 173, 7);
}
.app {
  background-color: #282c34;
  max-width: 100%;
  min-height: calc(100vh - 4rem);
  margin: 2rem;
  border-radius: 1rem;
  border: 1px solid gold;
}

.app__inner {
  display: grid;
  grid-template-columns: repeat(12 , 1fr);
  grid-gap: 1rem;
  margin: 1rem;
}

.app__left_side_inner {
  position: relative;
  max-width: 100%;
  height: var(--height_tracking_list);
  overflow-x: hidden;
}

.app__left_side {
  grid-column: span 7 / span 7;
  border: 1px solid silver;
  overflow-y: scroll;
}

.app__right_side {
  grid-column: span 5 / span 5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.app__right_side .app__right_side__top {
  height: var(--height_right_side_top);
  border: 1px solid silver;
  padding: 1rem;
  background-color: white;
}

.app__right_side .app__right_side__bottom{
  position: relative;
  height: var(--height_right_side_bottom);
  border: 1px solid silver;
  background-color: white;
}

.app h1 {
  color: white;
  font-size: 2.5rem;
  margin: 0;
  padding: 1rem;
  text-align: center;
}