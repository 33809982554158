/* Add Page Window Styling*/
.addPage_window{
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    position: absolute;
}

.addPage_form{
    height: 100%;
    display: grid;
    place-items: center;
}

.addPage_form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.addPage_form form input{
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid silver;
}
.addPage_form form select{
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid silver;
    width: 100%;
}

.addPage_form__save_button{
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid silver;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
}
.addPage_form__save_button:hover{
    background-color: var(--kss-gold-color);
    color: black;
    font-weight: bold;
    transition: all 0.3s;
}

.addPage_form__cancel_button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid silver;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
}

.addPage_form__cancel_button:hover {
    background-color: red;
    color: white;
    font-weight: bold;
    transition: all 0.3s;
}