.kss_hosting_stats_window {
    padding: 1rem;
}

.kss_hosting_stats {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    padding: 1rem;
}

.kss_hosting_stats__item {
    grid-column: span 4 / span 4;
}

.no_item_seleceted {
    display: grid;
    place-items: center;
    height: 100%;
}

.no_item_seleceted div {
    text-align: center;
}   

.stats_toolbar{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    border-top: 1px solid black;
}
.statsWindowButton{
    margin:0.25rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid silver;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: bold;
}

.statsWindowButton.__LinkChecker:hover{
    background-color: green;
    color: black;
}

.statsWindowButton.__delete:hover {
    background-color: #db1212;
    color: black;
}

.statsWindowButton.__Backup:hover {
    background-color: var(--kss-gold-color);
    color: black;
}

.no-bold{
    font-weight: normal;
}