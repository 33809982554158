
.searchbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchbox input {
    padding: 0.5rem;
    border: 1px solid black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none;
}

.searchbox button {
    padding: 0.5rem;
    border: 1px solid black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: black;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
}

.searchbox button:hover {
    background-color: var(--kss-gold-color);
    transition: background-color 0.3s;
}