.modal {
    position: absolute;
    z-index: 5;
    width: 100%;
    background-color: white;
    height: 100%;
}

.modal-content{
    display: grid;
    place-items: center;
    height: 100%;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal-content form input {
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
}

.modal-content form button {
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f1f1f1;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
}

.modal-content form button:hover {
    background-color: gold;
    transition: background-color 0.3s;
}

.close {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 4rem;
    font-weight: bold;
}

.close:hover {
    cursor: pointer;
}

