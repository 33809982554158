

/* Toolbar Styling */
.tracking_list_toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2.5rem;
}

.toolbar_btn{
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: bold;
    color:white;
    background-color: black;
    transition: background-color 0.3s;
    cursor: pointer;
}

.toolbar_btn.refresh:hover{
    background-color: var(--kss-gold-color);
    transition: background-color 0.3s;
}

.toolbar_btn.addPage:hover{
    background-color: #52db12 ;
    transition: background-color 0.3s;
}



.tracking_list_toolbar .tools {
    display: flex;
    gap: 1rem;
}